import { getEnv } from "lib/getEnv";
import manVan from "lib/images/van.png";
import binCollection from "lib/images/bin-collection.png";
import skip from "lib/images/skip.png";
import skipBag from "lib/images/skip-bag.png";
import houseClearance from "lib/images/furniture-collection.png";

const websiteUrl = getEnv("WEBSITE_URL") ?? "";

/**
 * TODO: Remove hardcoded data.
 * Instead get from either API or CMS: https://clearabee.atlassian.net/browse/HWA-626
 */
export const SERVICES = [
  {
    service: "Man & Van",
    link: "ManVan",
    image: manVan,
    buttonTestId: "manVan",
    sku: "MANVAN",
    useCases: [
      "Light bulky waste",
      "Domestic & business",
      "Indoor collections",
    ],
    readMore: `${websiteUrl}/rubbish-clearance`,
  },
  {
    service: "Skip Hire",
    link: "SkipHire",
    image: skip,
    buttonTestId: "skipHire",
    sku: "SKIPS",
    useCases: ["Soil / sand", "Bricks / rubble", "Garden waste"],
    readMore: `${websiteUrl}/skip-hire`,
  },
  // need to update this for house clearance
  {
    service: "House Clearance",
    link: "/book",
    sku: "HOUSECLEARANCE",
    image: houseClearance,
    useCases: ["Recliners", "Sofa beds", "Footstools"],
    buttonTestId: "houseClearance",
    readMore: `${websiteUrl}/house-clearance`,
  },
  {
    service: "Bin Collection",
    link: "BinCollection",
    image: binCollection,
    buttonTestId: "binCollection",
    sku: "BINCOL",
    useCases: ["Bagged waste", "Recycling waste", "Garden bin waste"],
    readMore: `${websiteUrl}/private-bin-collection`,
  },
  {
    service: "Skip Bags",
    link: "/book",
    sku: "SKIPBAGS",
    image: skipBag,
    useCases: [
      "Small DIY projects",
      "Installation waste",
      "Light / bulky waste",
    ],
    buttonTestId: "skipBags",
    readMore: `${websiteUrl}/skip-bags`,
  },
];
